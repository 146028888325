import { Center, Stack } from '@mantine/core'
import ReportScore from './ReportScore'
import ReportDetail from './ReportDetail'
import React from 'react'

interface ReportOverviewProps {
  data: any
  title: string
}

export default function ReportOverview({ data, title }: ReportOverviewProps) {
  return (
    <Stack gap={0} className={'p-2 bg-blue-500'}>
      <div className={'text-white text-sm'}>{data?.meta_info_code}</div>
      <ReportScore data={data} title={title} />
      <Center className={'mb-2 text-[12px] text-white px-4 mt-[-40px]'}>
        分析指数是根据网络行为大数据出具的分析评估参考分数，分数越高越好。该指数仅对本报告有效，不代表对报告查询人的综合定性评价。
      </Center>
      <Center className={'mb-2 text-[12px] text-white'}>报告生成时间：{data?.meta_info_date}</Center>
      <ReportDetail data={data} />
    </Stack>
  )
}
