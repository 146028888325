import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import SummarizedLoans from './SummarizedLoans'
import OverdueLoan from './OverdueLoan'
import LendingInstitution from './LendingInstitution'
import LoanPortfolio from './LoanPortfolio'

export default function LoanRecords({ data, title }: { data: any; title: string }) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <LendingInstitution data={data} />
      <LoanPortfolio data={data} />
      <OverdueLoan data={data} />
      <SummarizedLoans data={data} />
    </ReportWrapper>
  )
}
