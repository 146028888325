import { useEffect, useState } from 'react'

const useWechatAuth = () => {
  const [wxCode, setWxCode] = useState<string | null>(null)
  const [wxAuthLoading, setWxAuthLoading] = useState<boolean>(true)

  useEffect(() => {
    checkWechatAuth()
  }, [])

  const checkWechatAuth = () => {
    const isWeChat = /MicroMessenger/i.test(navigator.userAgent)

    if (!isWeChat) {
      setWxAuthLoading(false)
      return
    }

    const urlParams = new URLSearchParams(window.location.search)
    const wxCode = urlParams.get('code')

    if (!wxCode) {
      const appId = 'wx64b8471dd4ad89bc'
      const redirectUri = encodeURIComponent(window.location.href)
      const scope = 'snsapi_base'
      const state = 'STATE'

      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appId}&redirect_uri=${redirectUri}&response_type=code&scope=${scope}&state=${state}#wechat_redirect`
    } else {
      setWxCode(wxCode)
      setWxAuthLoading(false)
    }
  }

  return { wxCode, wxAuthLoading }
}

export default useWechatAuth
