import { Box, Stack, Text } from '@mantine/core'
import ReportItem from '../ReportItem'
import React from 'react'

interface ReportItemProps {
  number: number
  item: any
}

export default function CourtCase({ number, item }: ReportItemProps) {
  return (
    <Box
      bg={'var(--mantine-color-blue-light)'}
      p={'xs'}
      style={{
        borderRadius: 'var(--mantine-radius-sm)',
      }}
    >
      <Stack gap={'xs'} className={'text-gray-500'}>
        <Text size={'sm'} c={'dark'} fw={500}>
          案件（{number}）
        </Text>
        <ReportItem name={'案号'} value={item?.caseCode} />
        <ReportItem name={'案件类型'} value={item?.caseType} />
        <ReportItem name={'诉讼地位'} value={item?.casePosition} />
        <ReportItem name={'法院名称'} value={item?.courtName} />
        <ReportItem name={'立案时间'} value={item?.caseRegTime} />
        <ReportItem name={'结案时间'} value={item?.caseEndTime} />
        <ReportItem name={'案件状态'} value={item?.caseStatus} />
        <ReportItem name={'案由'} value={item?.caseReason} />
        <ReportItem name={'判决结果'} value={item?.caseResult} />
      </Stack>
    </Box>
  )
}
