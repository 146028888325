import http, { ResponseData } from './httpService'
import { IsaacMerchantRechargeReqVO, IsaacMerchantSignupReqVO } from '../typings/merchant'

export const signupMerchant = async (vo: IsaacMerchantSignupReqVO): Promise<void> => {
  await http.post<ResponseData<String>>('/fe/user/center/merchant/signup', vo)
}

export const recharge = async (param: IsaacMerchantRechargeReqVO): Promise<string> => {
  const { data } = await http.post<ResponseData<string>>('/fe/user/center/merchant/recharge', param)
  return data.data
}
