import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ImplementationCases from './ImplementationCases'

interface EnforcementCasesProps {
  data: any
  title: string
}

export default function EnforcementCases({ data, title }: EnforcementCasesProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ImplementationCases data={data} />
    </ReportWrapper>
  )
}
