import { Group, Stack } from '@mantine/core'
import React from 'react'
import ReportElement from './ReportElement'

export default function ReportElements() {
  return (
    <Stack>
      <div className={'text-left text-lg font-bold'}>报告主要内容</div>
      <Group>
        <ReportElement name={'基本信息'} />
        <ReportElement name={'婚恋信息'} />
        <ReportElement name={'贷款申请记录'} />
        <ReportElement name={'异常名单'} />
        <ReportElement name={'下款记录'} />
        <ReportElement name={'法院执行信息'} />
        <ReportElement name={'法院公告'} />
      </Group>
    </Stack>
  )
}
