import http, { ResponseData } from './httpService'

export const getEnumByName = async (name: string): Promise<Map<string, string>> => {
  const { data } = await http.get<ResponseData<Map<string, string>>>('/fe/dict/enumByName', {
    params: {
      name,
    },
  })
  return data.data
}

export const getDictByKey = async (key: string): Promise<string> => {
  const { data } = await http.get<ResponseData<string>>('/fe/dict/byKey', {
    params: {
      key,
    },
  })
  return data.data
}
