import React, { useEffect, useState } from 'react'
import '../App.css'
import QueryReportForm from './components/QueryReportForm'
import { Image, Stack } from '@mantine/core'
import ReportElements from './components/ReportElements'
import RightFloating from '../components/RightFloating'
import FloatingButton from '../components/FloatingButton'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { facade } from '../api/orderService'
import { TemplateMerchantInterface } from '../typings/orderTypes'

export default function QueryReport() {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const linkId = searchParams.get('linkId') || ''
  const [template, setTemplate] = useState<TemplateMerchantInterface>()

  useEffect(() => {
    if (linkId === '') {
      navigate('/login')
      return
    }
    facade(linkId)
      .then((res) => {
        setTemplate(res)
      })
      .catch(() => {})
  }, [linkId, navigate])

  return (
    <div className="max-w-xl m-auto">
      <div className="bg-[#3A55DD] pb-4">
        <Image src="./query_bg.jpg" />
        <div className="mx-4 bg-white rounded-3xl px-6 py-5 mb-4">
          <QueryReportForm template={template} />
        </div>
        <div className="mx-4 bg-white rounded-3xl px-6 py-5">
          <ReportElements />
        </div>
        <RightFloating>
          <Stack>
            <FloatingButton
              color={'green'}
              label={'历史报告'}
              onClick={() => {
                navigate('/orders')
              }}
            />
          </Stack>
        </RightFloating>
      </div>
    </div>
  )
}
