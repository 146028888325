import { Text } from '@mantine/core'
import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'

export default function ReportFooter() {
  return (
    <ReportWrapper>
      <LabeledContainer title={'报告说明'} />
      <Text c={'gray'} size={'sm'}>
        &nbsp;&nbsp;&nbsp;&nbsp;本报告的数据由用户本人明确授权后，我们才向相关合法存有用户个人数据的机构调取本报告相关内容，本平台只做大数据的获取与分析，仅向用户个人展示参考。
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;报告有效期为<b>30</b>天，过期自动删除。
        <br />
        &nbsp;&nbsp;&nbsp;&nbsp;若您的数据不全面，可能是数据具有延迟性或者合作信息机构未获取到您的数据。
        <b>若数据有错误请联系客服</b>。
      </Text>
    </ReportWrapper>
  )
}
