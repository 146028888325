import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm } from '@mantine/form'
import { Box, Button, Checkbox, Group, TextInput } from '@mantine/core'
import { IconPhone, IconShieldCheck } from '@tabler/icons-react'
import useVerificationCodeTimer from '../../hooks/useVerificationCodeTimer'
import { login } from '../../api/userService'
import { USER_TOKEN_KEY } from '../../constants/localStorageConstants'
import { Dialog } from 'antd-mobile'

export default function LoginForm() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { label, startTimer, isActive } = useVerificationCodeTimer()

  const form = useForm({
    initialValues: {
      phone: '',
      smsCode: '',
      termsOfService: false,
    },
    validate: {
      phone: (value) => (value === '' ? '请输入手机号' : !/^1[3-9]\d{9}$/.test(value) ? '请输入正确的手机号' : null),
      smsCode: (value) => (value === '' ? '请输入验证码' : value.length !== 6 ? '请输入6位的验证码' : null),
    },
  })

  const agreeTermsOfService = () => {
    return (
      <>
        我已阅读并同意
        <span
          className="text-orange-500"
          onClick={(e) => {
            e.preventDefault()
            // eslint-disable-next-line no-restricted-globals
            open('https://ucc.sjcyx.com/userAgreement.html', '_blank')
          }}
        >
          《用户协议》
        </span>
        和
        <span
          className="text-orange-500"
          onClick={(e) => {
            e.preventDefault()
            // eslint-disable-next-line no-restricted-globals
            open('https://ucc.sjcyx.com/privacyPolicy.html', '_blank')
          }}
        >
          《隐私政策》
        </span>
      </>
    )
  }

  const userLogin = (values: any) => {
    setLoading(true)
    login(values)
      .then((res) => {
        localStorage.setItem(USER_TOKEN_KEY, res.tokenValue)
        navigate('/orders')
      })
      .catch(() => {})
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Box>
      <form
        onSubmit={form.onSubmit((values) => {
          if (!values.termsOfService) {
            Dialog.confirm({
              content: agreeTermsOfService(),
              confirmText: '同意并登录',
              cancelText: '不同意',
              onConfirm: () => {
                form.setFieldValue('termsOfService', true)
                userLogin(values)
              },
            }).then()
          } else {
            userLogin(values)
          }
        })}
      >
        <TextInput
          mt="md"
          placeholder="请输入手机号"
          leftSection={<IconPhone size={16} />}
          {...form.getInputProps('phone')}
        />
        <TextInput
          mt="md"
          maxLength={6}
          placeholder="请输入验证码"
          leftSection={<IconShieldCheck size={16} />}
          rightSectionWidth={100}
          rightSection={
            <Button
              size="xs"
              variant="subtle"
              onClick={() => {
                const phone = form.values.phone
                const result = form.validateField('phone')
                if (result.hasError) {
                  return
                }
                startTimer({ phone: phone, type: 1 })
              }}
              disabled={isActive}
            >
              {label}
            </Button>
          }
          {...form.getInputProps('smsCode')}
        />
        <Checkbox
          color={'#3A55DD'}
          my="md"
          label={agreeTermsOfService()}
          {...form.getInputProps('termsOfService', { type: 'checkbox' })}
        />
        <Group justify="space-between" mt="md">
          <Button
            loading={loading}
            type="submit"
            fullWidth
            radius="md"
            size={'md'}
            styles={{
              root: {
                backgroundColor: '#3A55DD',
              },
            }}
          >
            登录
          </Button>
        </Group>
      </form>
    </Box>
  )
}
