import { Box, Stack, Text } from '@mantine/core'
import ReportItem from '../ReportItem'
import React from 'react'

interface FailureComplyCaseProps {
  number: number
  item: any
}

export default function FailureComplyCase({ number, item }: FailureComplyCaseProps) {
  return (
    <Box
      bg={'var(--mantine-color-blue-light)'}
      p={'xs'}
      style={{
        borderRadius: 'var(--mantine-radius-sm)',
      }}
    >
      <Stack gap={'xs'} className={'text-gray-500'}>
        <Text size={'sm'} c={'dark'} fw={500}>
          案件（{number}）
        </Text>
        <ReportItem name={'案号'} value={item?.caseCode} />
        <ReportItem name={'法院名称'} value={item?.courtName} />
        <ReportItem name={'立案时间'} value={item?.caseRegTime} />
        <ReportItem name={'曝光时间'} value={item?.casePublishTime} />
        <ReportItem name={'曝光信号描述'} value={item?.caseSignalDesc} />
      </Stack>
    </Box>
  )
}
