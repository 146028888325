import { Stack, Table, TableData, Tabs } from '@mantine/core'
import LabeledTitle from '../../../components/LabeledTitle'

interface InstitutionalApplicationRecordsProps {
  data: any
}

export default function InstitutionalApplicationRecords({ data }: InstitutionalApplicationRecordsProps) {
  const lastSevenTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['p2p', data?.nb_d7_apply_p2p_count ?? 0, data?.nb_d7_apply_p2p_org_count ?? 0],
      ['小贷', data?.nb_d7_apply_xd_count ?? 0, data?.nb_d7_apply_xd_org_count ?? 0],
      ['消费分期', data?.nb_d7_apply_xffq_count ?? 0, data?.nb_d7_apply_xffq_org_count ?? 0],
      ['现金分期', data?.nb_d7_apply_xjfq_count ?? 0, data?.nb_d7_apply_xjfq_org_count ?? 0],
      ['代偿分期', data?.nb_d7_apply_dcfq_count ?? 0, data?.nb_d7_apply_dcfq_org_count ?? 0],
      ['其他', data?.nb_d7_apply_qt_count, data?.nb_d7_apply_qt_org_count ?? 0],
    ],
  }

  const lastFifteenTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['p2p', data?.nb_d15_apply_p2p_count ?? 0, data?.nb_d15_apply_p2p_org_count ?? 0],
      ['小贷', data?.nb_d15_apply_xd_count ?? 0, data?.nb_d15_apply_xd_org_count ?? 0],
      ['消费分期', data?.nb_d15_apply_xffq_count ?? 0, data?.nb_d15_apply_xffq_org_count ?? 0],
      ['现金分期', data?.nb_d15_apply_xjfq_count ?? 0, data?.nb_d15_apply_xjfq_org_count ?? 0],
      ['代偿分期', data?.nb_d15_apply_dcfq_count ?? 0, data?.nb_d15_apply_dcfq_org_count ?? 0],
      ['其他', data?.nb_d15_apply_qt_count, data?.nb_d15_apply_qt_org_count ?? 0],
    ],
  }

  const lastMonthTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['p2p', data?.nb_m1_apply_p2p_count ?? 0, data?.nb_m1_apply_p2p_org_count ?? 0],
      ['小贷', data?.nb_m1_apply_xd_count ?? 0, data?.nb_m1_apply_xd_org_count ?? 0],
      ['消费分期', data?.nb_m1_apply_xffq_count ?? 0, data?.nb_m1_apply_xffq_org_count ?? 0],
      ['现金分期', data?.nb_m1_apply_xjfq_count ?? 0, data?.nb_m1_apply_xjfq_org_count ?? 0],
      ['代偿分期', data?.nb_m1_apply_dcfq_count ?? 0, data?.nb_m1_apply_dcfq_org_count ?? 0],
      ['其他', data?.nb_m1_apply_qt_count, data?.nb_m1_apply_qt_org_count ?? 0],
    ],
  }

  const lastThreeMonthsTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['p2p', data?.nb_m3_apply_p2p_count ?? 0, data?.nb_m3_apply_p2p_org_count ?? 0],
      ['小贷', data?.nb_m3_apply_xd_count ?? 0, data?.nb_m3_apply_xd_org_count ?? 0],
      ['消费分期', data?.nb_m3_apply_xffq_count ?? 0, data?.nb_m3_apply_xffq_org_count ?? 0],
      ['现金分期', data?.nb_m3_apply_xjfq_count ?? 0, data?.nb_m3_apply_xjfq_org_count ?? 0],
      ['代偿分期', data?.nb_m3_apply_dcfq_count ?? 0, data?.nb_m3_apply_dcfq_org_count ?? 0],
      ['其他', data?.nb_m3_apply_qt_count, data?.nb_m3_apply_qt_org_count ?? 0],
    ],
  }

  const lastSixMonthsTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['p2p', data?.nb_m6_apply_p2p_count ?? 0, data?.nb_m6_apply_p2p_org_count ?? 0],
      ['小贷', data?.nb_m6_apply_xd_count ?? 0, data?.nb_m6_apply_xd_org_count ?? 0],
      ['消费分期', data?.nb_m6_apply_xffq_count ?? 0, data?.nb_m6_apply_xffq_org_count ?? 0],
      ['现金分期', data?.nb_m6_apply_xjfq_count ?? 0, data?.nb_m6_apply_xjfq_org_count ?? 0],
      ['代偿分期', data?.nb_m6_apply_dcfq_count ?? 0, data?.nb_m6_apply_dcfq_org_count ?? 0],
      ['其他', data?.nb_m6_apply_qt_count, data?.nb_m6_apply_qt_org_count ?? 0],
    ],
  }

  const lastYearTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['p2p', data?.nb_m12_apply_p2p_count ?? 0, data?.nb_m12_apply_p2p_org_count ?? 0],
      ['小贷', data?.nb_m12_apply_xd_count ?? 0, data?.nb_m12_apply_xd_org_count ?? 0],
      ['消费分期', data?.nb_m12_apply_xffq_count ?? 0, data?.nb_m12_apply_xffq_org_count ?? 0],
      ['现金分期', data?.nb_m12_apply_xjfq_count ?? 0, data?.nb_m12_apply_xjfq_org_count ?? 0],
      ['代偿分期', data?.nb_m12_apply_dcfq_count ?? 0, data?.nb_m12_apply_dcfq_org_count ?? 0],
      ['其他', data?.nb_m12_apply_qt_count, data?.nb_m12_apply_qt_org_count ?? 0],
    ],
  }

  return (
    <Stack>
      <LabeledTitle title={'机构类别申请记录'} />
      <Tabs
        defaultValue="lastSeven"
        styles={{
          tab: {
            paddingRight: 8,
            paddingLeft: 8,
          },
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="lastSeven">近7天</Tabs.Tab>
          <Tabs.Tab value="lasetFifteen">近15天</Tabs.Tab>
          <Tabs.Tab value="lastMonth">近1月</Tabs.Tab>
          <Tabs.Tab value="lastThreeMonths">近3月</Tabs.Tab>
          <Tabs.Tab value="lastSixMonths">近6月</Tabs.Tab>
          <Tabs.Tab value="lastYear">近1年</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="lastSeven">
          <Table data={lastSevenTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lasetFifteen">
          <Table data={lastFifteenTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastMonth">
          <Table data={lastMonthTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastThreeMonths">
          <Table data={lastThreeMonthsTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastSixMonths">
          <Table data={lastSixMonthsTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastYear">
          <Table data={lastYearTableData} />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  )
}
