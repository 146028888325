import { Stack } from '@mantine/core'
import React from 'react'
import ReportItem from '../ReportItem'

interface ReportDetailProps {
  data: any
}

export default function ReportDetail({ data }: ReportDetailProps) {
  return (
    <Stack className={'bg-white rounded-xl p-3'}>
      {data?.meta_data_module_list.map((item: any, index: number) =>
        Object.entries(item).map(([key, value]) => (
          <ReportItem
            key={index}
            name={value as string}
            value={
              (data?.meta_index_focus_list ?? []).some((item: string) => {
                return item === key
              })
                ? '异常'
                : '正常'
            }
            isNormal={
              !(data?.meta_index_focus_list ?? []).some((item: string) => {
                return item === key
              })
            }
          />
        ))
      )}
    </Stack>
  )
}
