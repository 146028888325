import { LoadingOverlay, Stack } from '@mantine/core'
import React, { useEffect, useState } from 'react'
import ReportFooter from '../Report/components/ReportFooter'
import { useNavigate, useParams } from 'react-router-dom'
import { reportSample } from '../api/reportService'
import { getModuleNodes } from '../Report/components/ModuleUtil'
import RightFloating from '../components/RightFloating'
import FloatingButton from '../components/FloatingButton'
import Share from '../components/Share'

export default function Sample() {
  const navigate = useNavigate()
  let { templateId } = useParams()
  const [data, setData] = useState<Map<String, any>>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [shareVisible, setShareVisible] = useState<boolean>(false)

  useEffect(() => {
    if (templateId === '') {
      navigate('/')
    }
    const fetchData = async () => {
      reportSample(templateId!)
        .then((res) => {
          setData(res)
        })
        .catch((err) => {})
        .finally(() => setIsLoading(false))
    }
    fetchData().then()
  }, [navigate, templateId])

  return (
    <div className={'max-w-xl m-auto'}>
      {isLoading && <LoadingOverlay zIndex={1000} visible overlayProps={{ radius: 'sm', blur: 2 }} />}
      <Stack>
        {getModuleNodes(data)}
        <ReportFooter />
      </Stack>
      <RightFloating>
        <Stack>
          <FloatingButton color={'black'} label={'示例报告'} />
          <FloatingButton
            color={'green'}
            label={'历史报告'}
            onClick={() => {
              navigate('/orders')
            }}
          />
          <FloatingButton
            color={'yellow'}
            label={'分享报告'}
            onClick={() => {
              setShareVisible(true)
            }}
          />
        </Stack>
      </RightFloating>
      <Share shareVisible={shareVisible} setShareVisible={setShareVisible} />
    </div>
  )
}
