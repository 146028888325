export default function Facts() {
  return (
    <section>
      <div className="pb-16 px-5">
        <div className="mx-auto flex w-full max-w-[798px] flex-col rounded-[36px] bg-black py-4 md:py-8 text-white">
          <div className="grid  grid-cols-1 md:grid-cols-2 gap-4">
            <div className="flex flex-col items-center justify-center">
              <h4 className="text-4xl mb-2 font-bold">1000+</h4>
              <p className="text-lg">用户数量</p>
            </div>
            <div className="flex flex-col items-center justify-center">
              <h4 className="text-4xl mb-2 font-bold">10000+</h4>
              <p className="text-lg">查询报告数量</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
