import React from 'react'
import MeiQiaWidget from './MeiQiaWidget'

export default function MeiQiaWrapper({ children }: { children: React.ReactNode }) {
  return (
    <>
      <MeiQiaWidget />
      {children}
    </>
  )
}
