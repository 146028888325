import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import PriorityRisk from './PriorityRisk'
import CriminalRecordRisk from './CriminalRecordRisk'

export default function BadInformationDetailDetection({ data, title }: { data: any; title: string }) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <PriorityRisk data={data} />
      <CriminalRecordRisk data={data} />
    </ReportWrapper>
  )
}
