import React from 'react'
import { Helmet } from 'react-helmet'

const MeiQiaWidget = () => (
  <>
    <Helmet>
      <script>
        {`
       (function(a, b, c, d, e, j, s) {
        a[d] = a[d] || function() {
            (a[d].a = a[d].a || []).push(arguments)
        };
        j = b.createElement(c),
            s = b.getElementsByTagName(c)[0];
        j.async = true;
        j.charset = 'UTF-8';
        j.src = 'https://static.meiqia.com/widget/loader.js';
        s.parentNode.insertBefore(j, s);
    })(window, document, 'script', '_MEIQIA');
    _MEIQIA('entId', '671e05954b593c31ebe5d04801bf1f3a');
        _MEIQIA('init');
        `}
      </script>
    </Helmet>
  </>
)

export default MeiQiaWidget
