export default function Feature() {
  return (
    <section>
      <div className="mx-auto max-w-7xl px-5 pb-16 md:px-10 md:pb-24 lg:pb-28">
        <div className="mx-auto w-full max-w-3xl text-center mb-8  md:mb-12 lg:mb-16">
          <h2 className="text-3xl font-semibold md:text-5xl">
            让每一分信任
            <span className="bg-[url('https://assets.website-files.com/63904f663019b0d8edf8d57c/63915f9749aaab0572c48dae_Rectangle%2018.svg')] bg-cover bg-center bg-no-repeat px-4 text-white">
              都不被辜负
            </span>
          </h2>
        </div>
        <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-8 md:grid-cols-3 lg:gap-12">
          <div className="relative mb-8 flex flex-col rounded-2xl border border-solid border-black p-8 [box-shadow:rgb(0,_0,_0)_9px_9px] lg:mb-4">
            <div className="absolute -top-8 bottom-auto left-auto right-4 flex h-16 w-16 flex-col items-center justify-center rounded-full border border-solid border-[#9b9b9b] bg-white [box-shadow:rgb(0,_0,_0)_0px_5px] lg:right-8">
              <img
                src="https://assets.website-files.com/63904f663019b0d8edf8d57c/639157f1a197859a6cd7f265_image%203.png"
                alt=""
                className="relative z-10 inline-block h-8"
              />
              <div className="absolute z-0 h-8 w-8 rounded-full border border-[#c0d1ff] bg-[#c0d1ff]"></div>
            </div>
            <p className="mb-4 text-xl font-semibold">个人风险</p>
            <p>
              如果出借过身份证；经常接到催债短信或陌生人的骚扰电话；在非官方、陌生APP或扫描陌生二维码进行刷脸等以上情况，赶紧自查个人风险问题，预防身份被冒用。
            </p>
          </div>
          <div className="relative mb-8 flex flex-col rounded-2xl border border-solid border-black p-8 [box-shadow:rgb(0,_0,_0)_9px_9px] lg:mb-4">
            <div className="absolute -top-8 bottom-auto left-auto right-4 flex h-16 w-16 flex-col items-center justify-center rounded-full border border-solid border-[#9b9b9b] bg-white [box-shadow:rgb(0,_0,_0)_0px_5px] lg:right-8">
              <img
                src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63915859fa889834c4f1ff92_image%203-2.png"
                alt=""
                className="relative z-10 inline-block h-8"
              />
              <div className="absolute z-0 h-8 w-8 rounded-full border border-[#c0d1ff] bg-[#c0d1ff]"></div>
            </div>
            <p className="mb-4 text-xl font-semibold">婚恋报告</p>
            <p>
              相亲、恋爱、社交，想要全面了解他/她的婚姻状况、教育水平、资产情况、消费水平、社交风险、个人信誉等信息，用优查查，2分钟搞定。
            </p>
          </div>
          {/*<div className="relative mb-8 flex flex-col rounded-2xl border border-solid border-black p-8 [box-shadow:rgb(0,_0,_0)_9px_9px] lg:mb-4">*/}
          {/*  <div className="absolute -top-8 bottom-auto left-auto right-4 flex h-16 w-16 flex-col items-center justify-center rounded-full border border-solid border-[#9b9b9b] bg-white [box-shadow:rgb(0,_0,_0)_0px_5px] lg:right-8">*/}
          {/*    <img*/}
          {/*      src="https://assets.website-files.com/63904f663019b0d8edf8d57c/639158510667812dff08e1af_image%203-4.png"*/}
          {/*      alt=""*/}
          {/*      className="relative z-10 inline-block h-8"*/}
          {/*    />*/}
          {/*    <div className="absolute z-0 h-8 w-8 rounded-full border border-[#c0d1ff] bg-[#c0d1ff]"></div>*/}
          {/*  </div>*/}
          {/*  <p className="mb-4 text-xl font-semibold">小微企业信用报告</p>*/}
          {/*  <p>*/}
          {/*    小微企业主个人属性延伸至相关小微企业信息，生成小微企业主报告。可根据企业主信息，判断是否予以合作，剖析企业履约能力，评估企业实力和潜在风险，及时有效规避风险发生等问题。*/}
          {/*  </p>*/}
          {/*</div>*/}
          <div className="relative mb-8 flex flex-col rounded-2xl border border-solid border-black p-8 [box-shadow:rgb(0,_0,_0)_9px_9px] lg:mb-4">
            <div className="absolute -top-8 bottom-auto left-auto right-4 flex h-16 w-16 flex-col items-center justify-center rounded-full border border-solid border-[#9b9b9b] bg-white [box-shadow:rgb(0,_0,_0)_0px_5px] lg:right-8">
              <img
                src="https://assets.website-files.com/63904f663019b0d8edf8d57c/6391585b7b7cd87baef5e9ec_image%203-1.png"
                alt=""
                className="relative z-10 inline-block h-8"
              />
              <div className="absolute z-0 h-8 w-8 rounded-full border border-[#c0d1ff] bg-[#c0d1ff]"></div>
            </div>
            <p className="mb-4 text-xl font-semibold">家政报告</p>
            <p>保姆阿姨安心选，优查查为你提供身份认证、个人情况、案件记录等多维度查询，助你降低雇佣风险。</p>
          </div>
          <div className="relative mb-8 flex flex-col rounded-2xl border border-solid border-black p-8 [box-shadow:rgb(0,_0,_0)_9px_9px] lg:mb-4">
            <div className="absolute -top-8 bottom-auto left-auto right-4 flex h-16 w-16 flex-col items-center justify-center rounded-full border border-solid border-[#9b9b9b] bg-white [box-shadow:rgb(0,_0,_0)_0px_5px] lg:right-8">
              <img
                src="https://assets.website-files.com/63904f663019b0d8edf8d57c/639158557ac2b528531836f1_image%203-3.png"
                alt=""
                className="relative z-10 inline-block h-8"
              />
              <div className="absolute z-0 h-8 w-8 rounded-full border border-[#c0d1ff] bg-[#c0d1ff]"></div>
            </div>
            <p className="mb-4 text-xl font-semibold">租赁报告</p>
            <p>
              基于物品租赁应用场景，租赁报告为出租方提供一些可以客观判断租赁方履约能力的依据，帮助出租方预防租赁风险。
            </p>
          </div>
          <div className="relative mb-8 flex flex-col rounded-2xl border border-solid border-black p-8 [box-shadow:rgb(0,_0,_0)_9px_9px] lg:mb-4">
            <div className="absolute -top-8 bottom-auto left-auto right-4 flex h-16 w-16 flex-col items-center justify-center rounded-full border border-solid border-[#9b9b9b] bg-white [box-shadow:rgb(0,_0,_0)_0px_5px] lg:right-8">
              <img
                src="https://assets.website-files.com/63904f663019b0d8edf8d57c/639157f3db4f4b8767c499ba_image%203-5.png"
                alt=""
                className="relative z-10 inline-block h-8"
              />
              <div className="absolute z-0 h-8 w-8 rounded-full border border-[#c0d1ff] bg-[#c0d1ff]"></div>
            </div>
            <p className="mb-4 text-xl font-semibold">更多报告</p>
            <p>下载 App 查询更多报告。</p>
          </div>
        </div>
      </div>
    </section>
  )
}
