import { Button } from '@mantine/core'

interface NumberTagProps {
  label: string
  value: string
  isSelected: boolean
  onClick: (value: string) => void
}

export default function NumberTag({ label, value, isSelected, onClick }: NumberTagProps) {
  return (
    <Button
      variant={isSelected ? 'gradient' : 'light'}
      gradient={{ from: 'teal', to: 'indigo', deg: 190 }}
      onClick={() => {
        onClick(value)
      }}
    >
      {label}
    </Button>
  )
}
