import { Box, Image } from '@mantine/core'
import React from 'react'
import LoginForm from './components/LoginForm'

export default function Login() {
  return (
    <div className={'max-w-xl m-auto'}>
      <Box className="bg-[#3A55DD] pb-4 h-screen">
        <Image src="./query_bg.jpg" />
        <div className="mx-4 bg-white rounded-3xl px-6 py-5 mb-4">
          <LoginForm />
        </div>
      </Box>
    </div>
  )
}
