import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import BankApplicationRecordsOverview from './BankApplicationRecordsOverview'
import InstitutionalApplicationRecords from './InstitutionalApplicationRecords'
import SpecialPeriodApplicationRecords from './SpecialPeriodApplicationRecords'

interface BankApplicationRecordsProps {
  data: any
  title: string
}

export default function BankApplicationRecords({ data, title }: BankApplicationRecordsProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <BankApplicationRecordsOverview data={data} />
      <InstitutionalApplicationRecords data={data} />
      <SpecialPeriodApplicationRecords data={data} />
    </ReportWrapper>
  )
}
