import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import RelatedPartyBankFailureFraud from './RelatedPartyBankFailureFraud'
import RelatedPartyOnlineLoanFraud from './RelatedPartyOnlineLoanFraud'
import RelatedPartyUnusualBehavior from './RelatedPartyUnusualBehavior'

interface RelatedPartyAnomaliesProps {
  data: any
  title: string
}

export default function RelatedPartyAnomalies({ data, title }: RelatedPartyAnomaliesProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <RelatedPartyBankFailureFraud data={data} />
      <RelatedPartyOnlineLoanFraud data={data} />
      <RelatedPartyUnusualBehavior data={data} />
    </ReportWrapper>
  )
}
