import Hero from './components/Hero'
import Facts from './components/Facts'
import Feature from './components/Feature'
import Footer from './components/Footer'

export default function Home() {
  return (
    <>
      <Hero />
      <Facts />
      <Feature />
      <Footer />
    </>
  )
}
