import React from 'react'
import { Box, Image } from '@mantine/core'
import RechargeForm from './components/RechargeForm'
import { Helmet } from 'react-helmet'

export default function Recharge() {
  return (
    <div className={'max-w-xl m-auto'}>
      <Helmet>
        <title>优查查-代理商充值</title>
      </Helmet>
      <Box className="bg-[#DBE2FE] pb-4 h-screen">
        <Image src="./invite.png" />
        <div className="mx-4 bg-white rounded-3xl px-6 py-5 mb-4">
          <RechargeForm />
        </div>
        <Box className="flex justify-center mt-10">
          <Image src="./footer_logo.png" h={30} w={101} />
        </Box>
      </Box>
    </div>
  )
}
