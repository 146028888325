import LabeledTitle from '../../../components/LabeledTitle'
import ReportItem from '../ReportItem'
import React from 'react'

export default function OverdueLoan({ data }: { data: any }) {
  return (
    <>
      <LabeledTitle title={'贷款逾期'} />
      <ReportItem
        name={'当前是否存在逾期未结清'}
        value={data?.loan_info_xyp_cpl0044 === '1' ? '是' : '否'}
        isNormal={data?.loan_info_xyp_cpl0044 === '0'}
      />
      <ReportItem
        name={'当前逾期机构数'}
        value={
          data?.loan_info_xyp_cpl0071 === '0'
            ? '0'
            : data?.loan_info_xyp_cpl0071 === '1'
              ? '1-2'
              : data?.loan_info_xyp_cpl0071 === '2'
                ? '2-4'
                : '4+'
        }
        isNormal={data?.loan_info_xyp_cpl0071 === '0'}
      />
      <ReportItem
        name={'当前逾期⾦额(元)'}
        value={
          data?.loan_info_xyp_cpl0072 === '0'
            ? '0'
            : data?.loan_info_xyp_cpl0072 === '1'
              ? '0-1000'
              : data?.loan_info_xyp_cpl0072 === '2'
                ? '1000-2000'
                : data?.loan_info_xyp_cpl0072 === '3'
                  ? '2000-3000'
                  : data?.loan_info_xyp_cpl0072 === '4'
                    ? '3000-5000'
                    : data?.loan_info_xyp_cpl0072 === '5'
                      ? '5000-7000'
                      : data?.loan_info_xyp_cpl0072 === '6'
                        ? '7000-11000'
                        : '11000+'
        }
        isNormal={data?.loan_info_xyp_cpl0072 === '0'}
      />
    </>
  )
}
