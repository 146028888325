import LabeledTitle from '../../../components/LabeledTitle'
import { Stack } from '@mantine/core'
import HitItem from '../HitItem'

interface OnlineLendingOutOfConnectionFraudProps {
  data: any
}

export default function OnlineLendingOutOfConnectionFraud({ data }: OnlineLendingOutOfConnectionFraudProps) {
  return (
    <Stack>
      <LabeledTitle title={'网贷欺诈失联行为'} />
      <HitItem
        name={'网络小贷'}
        value={data?.als_wlxdqzsl_count > 0 ? '命中' : '未命中'}
        isNormal={data?.als_wlxdqzsl_count === 0}
        hitCount={data?.als_wlxdqzsl_count || 0}
        recentHitTime={data?.als_wlxdqzsl_count === 0 ? '-' : `近${data?.als_wlxdqzsl_duration + 1}年内`}
      />
      <HitItem
        name={'地方小贷'}
        value={data?.als_dfxdqxsl_count > 0 ? '命中' : '未命中'}
        isNormal={data?.als_dfxdqxsl_count === 0}
        hitCount={data?.als_dfxdqxsl_count || 0}
        recentHitTime={data?.als_dfxdqxsl_count === 0 ? '-' : `近${data?.als_dfxdqxsl_duration + 1}年内`}
      />
      <HitItem
        name={'消费金融公司'}
        value={data?.als_xfjrgsqzsl_count > 0 ? '命中' : '未命中'}
        isNormal={data?.als_xfjrgsqzsl_count === 0}
        hitCount={data?.als_xfjrgsqzsl_count || 0}
        recentHitTime={data?.als_xfjrgsqzsl_count === 0 ? '-' : `近${data?.als_xfjrgsqzsl_duration + 1}年内`}
      />
      <HitItem
        name={'融资租赁公司'}
        value={data?.als_rzzlgsqzsl_count > 0 ? '命中' : '未命中'}
        isNormal={data?.als_rzzlgsqzsl_count === 0}
        hitCount={data?.als_rzzlgsqzsl_count || 0}
        recentHitTime={data?.als_rzzlgsqzsl_count === 0 ? '-' : `近${data?.als_rzzlgsqzsl_duration + 1}年内`}
      />
      <HitItem
        name={'汽车金融公司'}
        value={data?.als_qcjrgsqzsl_count > 0 ? '命中' : '未命中'}
        isNormal={data?.als_qcjrgsqzsl_count === 0}
        hitCount={data?.als_qcjrgsqzsl_count || 0}
        recentHitTime={data?.als_qcjrgsqzsl_count === 0 ? '-' : `近${data?.als_qcjrgsqzsl_duration + 1}年内`}
      />
      <HitItem
        name={'其他金融公司'}
        value={data?.als_qtjrgsqzsl_count > 0 ? '命中' : '未命中'}
        isNormal={data?.als_qtjrgsqzsl_count === 0}
        hitCount={data?.als_qtjrgsqzsl_count || 0}
        recentHitTime={data?.als_qtjrgsqzsl_count === 0 ? '-' : `近${data?.als_qtjrgsqzsl_duration + 1}年内`}
      />
    </Stack>
  )
}
