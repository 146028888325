import { Group, Popover, Text } from '@mantine/core'
import React from 'react'
import { IconHelpOctagonFilled } from '@tabler/icons-react'

interface ReportItemProps {
  name: string
  value: string
  isNormal?: boolean
  helpTip?: string
}

export default function ReportItem({ name, value, isNormal = true, helpTip }: ReportItemProps) {
  return (
    <Group justify={'space-between'}>
      <Group gap={2}>
        <Text size={'sm'}>{name}</Text>
        {helpTip && (
          <Popover width={200} position="bottom" withArrow shadow="md">
            <Popover.Target>
              <IconHelpOctagonFilled size={16} className={'text-blue-500'} />
            </Popover.Target>
            <Popover.Dropdown>
              <Text size="xs">{helpTip}</Text>
            </Popover.Dropdown>
          </Popover>
        )}
      </Group>
      <Text size={'sm'} c={isNormal ? 'dark' : 'red'}>
        {value}
      </Text>
    </Group>
  )
}
