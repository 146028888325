import React from 'react'
import { Box, Image } from '@mantine/core'
import InviteForm from './components/InviteForm'

export default function Invite() {
  return (
    <div className={'max-w-xl m-auto'}>
      <Box className="bg-[#DBE2FE] pb-4 h-screen">
        <Image src="./invite.png" />
        <div className="mx-4 bg-white rounded-3xl px-6 py-5 mb-4">
          <InviteForm />
        </div>
        <Box className="flex justify-center mt-10">
          <Image src="./footer_logo.png" h={30} w={101} />
        </Box>
      </Box>
    </div>
  )
}
