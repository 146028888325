import { Center, Image, Stack, Text } from '@mantine/core'

interface PageEmptyProps {
  image: string
  title: string
  size?: number
}

export default function PageEmpty({ image, title, size = 200 }: PageEmptyProps) {
  return (
    <Center>
      <Stack align="center">
        <Image radius="md" src={image} w={size} h={size} />
        <Text c="dimmed">{title}</Text>
      </Stack>
    </Center>
  )
}
