export default function Hero() {
  return (
    <section className="relatve">
      <div className="mx-auto w-full max-w-7xl px-5 py-12 md:px-10">
        <div className="mx-auto mb-12 w-full max-w-3xl text-center md:mb-12 lg:mb-12">
          <h1 className="mb-4 text-4xl font-semibold md:text-6xl">
            大数据信用报告查询平台
            <br />
            <span className="bg-[url('https://assets.website-files.com/63904f663019b0d8edf8d57c/6390526ac2a607693620c97b_Rectangle%2010.svg')] bg-cover bg-center px-4 text-white">
              优查查
            </span>
          </h1>
          <p className="mx-auto mb-5 max-w-[528px] text-xl text-[#636262] lg:mb-8">
            提供婚姻状态、失信情况、经营状况、法院案件、失信检测、执行情况、不良信息、申请记录等多维度信息查询的大数据平台。
          </p>
          <div className="flex justify-center">
            <a
              href={'https://ucc.sjcyx.com/queryReport?linkId=00140504619395260417'}
              target={'_blank'}
              className="mr-5 inline-block rounded-xl bg-black px-8 py-4 text-center font-semibold text-white [box-shadow:rgb(19,_83,_254)_6px_6px] md:mr-6"
            >
              立即查询
            </a>
            <a
              target={'_blank'}
              href={'https://www.pgyer.com/isaac'}
              className="flex max-w-full flex-row items-center justify-center rounded-xl border border-solid border-[#1353fe] px-6 py-3 font-semibold text-[#1353fe] [box-shadow:rgb(19,_83,_254)_6px_6px]"
            >
              <img
                src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63905a575ec39b6784fc687c_Play.svg"
                alt=""
                className="mr-2 inline-block w-6"
              />
              <p className="text-black">下载 App</p>
            </a>
          </div>
        </div>
        <div className="relative mx-auto lg:w-[358px] w-[206px]">
          <img src="./app.png" alt="" className="inline-block rounded-xl object-cover sm:rounded-2xl" />

          <div
            className={'absolute top-[200px] left-[20px] lg:top-[300px] lg:left-[-100px]'}
            style={{ transform: 'translateX(-100px) scale(1) translateZ(0px)' }}
          >
            <img className={'w-[166px] lg:w-[327px]'} src="./income.png" alt="" />
          </div>
          <div
            className={'absolute top-[30px] right-[10px] lg:top-[60px] lg:right-[-120px]'}
            style={{ transform: 'translateX(90px) scale(1) translateZ(0px)' }}
          >
            <img className={'w-[166px] lg:w-[327px]'} src="./report.png" alt="" />
          </div>
        </div>
      </div>
      <img
        src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63905b9f809b5c8180ce30c5_pattern-1.svg"
        alt=""
        className="absolute bottom-0 left-0 right-auto top-auto -z-10 inline-block md:bottom-1/2 md:left-0 md:right-auto md:top-auto"
      />
      <img
        src="https://assets.website-files.com/63904f663019b0d8edf8d57c/63905ba1538296b3f50a905e_pattern-2.svg"
        alt=""
        className="absolute bottom-auto left-auto right-0 top-0 -z-10 hidden sm:inline-block"
      />
    </section>
  )
}
