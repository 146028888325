import LabeledTitle from '../../../components/LabeledTitle'
import React from 'react'
import CourtCase from './CourtCase'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

export default function CivilCases({ data }: { data: any }) {
  return (
    <>
      <LabeledTitle title={'民事案件检测'} />
      <ExpandableListWrapper
        isEmpty={(data?.court_case_info?.civil ?? []).length === 0 || data?.court_case_info?.civil === undefined}
      >
        {(data?.court_case_info?.civil ?? []).map((item: any, index: number) => {
          return <CourtCase number={index + 1} key={index} item={item} />
        })}
      </ExpandableListWrapper>
    </>
  )
}
