import LabeledTitle from '../../../components/LabeledTitle'
import React from 'react'
import { Stack, Table, TableData } from '@mantine/core'

export default function SummarizedLoans({ data }: { data: any }) {
  const tableData: TableData = {
    head: ['', '贷款机构数', '还款成功笔数', '还款成功总⾦额(元)', '交易失败笔数', '交易失败总⾦额(元)'],
    body: [
      [
        '近1天',
        data?.loan_info_xyp_cpl0070 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0070 === '1'
            ? '0-1'
            : data?.loan_info_xyp_cpl0070 === '2'
              ? '1-3'
              : '3+',
        data?.loan_info_xyp_cpl0017 === '0' ? '0' : data?.loan_info_xyp_cpl0017 === '1' ? '0-3' : '3+',
        data?.loan_info_xyp_cpl0033 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0033 === '1'
            ? '0-1500'
            : data?.loan_info_xyp_cpl0033 === '2'
              ? '1500-5000'
              : '5000+',
        data?.loan_info_xyp_cpl0016 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0016 === '1'
            ? '0-3'
            : data?.loan_info_xyp_cpl0016 === '1'
              ? '3-5'
              : '5+',
        data?.loan_info_xyp_cpl0032 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0032 === '1'
            ? '0-1500'
            : data?.loan_info_xyp_cpl0032 === '2'
              ? '1500-2500'
              : data?.loan_info_xyp_cpl0032 === '3'
                ? '2500-5000'
                : '5000+',
      ],
      [
        '近7天',
        data?.loan_info_xyp_cpl0009 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0009 === '1'
            ? '0-1'
            : data?.loan_info_xyp_cpl0009 === '2'
              ? '1-9'
              : '9+',
        data?.loan_info_xyp_cpl0019 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0019 === '1'
            ? '0-3'
            : data?.loan_info_xyp_cpl0019 === '2'
              ? '3-13'
              : '13+',
        data?.loan_info_xyp_cpl0035 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0035 === '1'
            ? '0-2000'
            : data?.loan_info_xyp_cpl0035 === '2'
              ? '2000-17000'
              : '17000+',
        data?.loan_info_xyp_cpl0018 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0018 === '1'
            ? '0-3'
            : data?.loan_info_xyp_cpl0018 === '2'
              ? '3-5'
              : data?.loan_info_xyp_cpl0018 === '3'
                ? '5-7'
                : '7+',
        data?.loan_info_xyp_cpl0034 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0034 === '1'
            ? '0-2000'
            : data?.loan_info_xyp_cpl0034 === '2'
              ? '2000-10000'
              : data?.loan_info_xyp_cpl0034 === '3'
                ? '10000-170000'
                : data?.loan_info_xyp_cpl0034 === '4'
                  ? '17000-260000'
                  : '260000+',
      ],
      [
        '近14天',
        data?.loan_info_xyp_cpl0010 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0010 === '1'
            ? '0-1'
            : data?.loan_info_xyp_cpl0010 === '2'
              ? '1-3'
              : data?.loan_info_xyp_cpl0010 === '3'
                ? '3-10'
                : '10+',
        data?.loan_info_xyp_cpl0021 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0021 === '1'
            ? '0-5'
            : data?.loan_info_xyp_cpl0021 === '2'
              ? '5-21'
              : '21+',
        data?.loan_info_xyp_cpl0037 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0037 === '1'
            ? '0-9000'
            : data?.loan_info_xyp_cpl0037 === '1'
              ? '9000-31000'
              : '31000+',
        data?.loan_info_xyp_cpl0020 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0020 === '1'
            ? '0-3'
            : data?.loan_info_xyp_cpl0020 === '2'
              ? '3-5'
              : data?.loan_info_xyp_cpl0020 === '3'
                ? '5-15'
                : '15+',
        data?.loan_info_xyp_cpl0036 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0036 === '1'
            ? '0-1000'
            : data?.loan_info_xyp_cpl0036 === '2'
              ? '1000-4000'
              : data?.loan_info_xyp_cpl0036 === '3'
                ? '4000-9000'
                : data?.loan_info_xyp_cpl0036 === '4'
                  ? '9000-30000'
                  : '30000+',
      ],
      [
        '近21天',
        data?.loan_info_xyp_cpl0063 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0063 === '1'
            ? '0-3'
            : data?.loan_info_xyp_cpl0063 === '2'
              ? '3-6'
              : data?.loan_info_xyp_cpl0063 === '3'
                ? '6-11'
                : '11+',
        data?.loan_info_xyp_cpl0064 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0064 === '1'
            ? '0-5'
            : data?.loan_info_xyp_cpl0064 === '2'
              ? '5-27'
              : '27+',
        data?.loan_info_xyp_cpl0067 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0067 === '1'
            ? '0-1000'
            : data?.loan_info_xyp_cpl0067 === '2'
              ? '1000-34000'
              : '34000+',
        data?.loan_info_xyp_cpl0065 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0065 === '1'
            ? '0-3'
            : data?.loan_info_xyp_cpl0065 === '2'
              ? '3-5'
              : data?.loan_info_xyp_cpl0065 === '3'
                ? '5-20'
                : '20+',
        data?.loan_info_xyp_cpl0066 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0066 === '1'
            ? '0-1000'
            : data?.loan_info_xyp_cpl0066 === '2'
              ? '1000-2000'
              : data?.loan_info_xyp_cpl0066 === '3'
                ? '2000-30000'
                : data?.loan_info_xyp_cpl0066 === '4'
                  ? '30000-50000'
                  : '50000+',
      ],
      [
        '近30天',
        data?.loan_info_xyp_cpl0011 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0011 === '1'
            ? '0-1'
            : data?.loan_info_xyp_cpl0011 === '2'
              ? '1-4'
              : data?.loan_info_xyp_cpl0011 === '3'
                ? '4-11'
                : '11+',
        data?.loan_info_xyp_cpl0023 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0023 === '1'
            ? '0-7'
            : data?.loan_info_xyp_cpl0023 === '2'
              ? '7-34'
              : '34+',
        data?.loan_info_xyp_cpl0039 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0039 === '1'
            ? '0-10000'
            : data?.loan_info_xyp_cpl0039 === '2'
              ? '10000-36000'
              : '36000+',
        data?.loan_info_xyp_cpl0022 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0022 === '1'
            ? '0-3'
            : data?.loan_info_xyp_cpl0022 === '2'
              ? '3-5'
              : data?.loan_info_xyp_cpl0022 === '3'
                ? '5-34'
                : '34+',
        data?.loan_info_xyp_cpl0038 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0038 === '1'
            ? '0-6000'
            : data?.loan_info_xyp_cpl0038 === '2'
              ? '6000-10000'
              : data?.loan_info_xyp_cpl0038 === '3'
                ? '10000-50000'
                : '50000+',
      ],
      [
        '近90天',
        data?.loan_info_xyp_cpl0012 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0012 === '1'
            ? '0-5'
            : data?.loan_info_xyp_cpl0012 === '2'
              ? '5-10'
              : data?.loan_info_xyp_cpl0012 === '3'
                ? '10-17'
                : '17+',
        data?.loan_info_xyp_cpl0025 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0025 === '1'
            ? '0-2'
            : data?.loan_info_xyp_cpl0025 === '2'
              ? '2-31'
              : '31+',
        data?.loan_info_xyp_cpl0041 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0041 === '1'
            ? '0-13000'
            : data?.loan_info_xyp_cpl0041 === '2'
              ? '13000-49000'
              : '49000+',
        data?.loan_info_xyp_cpl0024 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0024 === '1'
            ? '0-6'
            : data?.loan_info_xyp_cpl0024 === '2'
              ? '6-22'
              : data?.loan_info_xyp_cpl0024 === '3'
                ? '22-56'
                : '56+',
        data?.loan_info_xyp_cpl0040 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0040 === '1'
            ? '0-10000'
            : data?.loan_info_xyp_cpl0040 === '2'
              ? '10000-50000'
              : data?.loan_info_xyp_cpl0040 === '3'
                ? '50000-80000'
                : '80000+',
      ],
      [
        '近180天',
        data?.loan_info_xyp_cpl0013 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0013 === '1'
            ? '0-4'
            : data?.loan_info_xyp_cpl0013 === '2'
              ? '4-7'
              : data?.loan_info_xyp_cpl0013 === '3'
                ? '7-13'
                : '13+',
        data?.loan_info_xyp_cpl0027 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0027 === '1'
            ? '0-3'
            : data?.loan_info_xyp_cpl0027 === '2'
              ? '3-27'
              : data?.loan_info_xyp_cpl0027 === '3'
                ? '27-50'
                : '50+',
        data?.loan_info_xyp_cpl0043 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0043 === '1'
            ? '0-2000'
            : data?.loan_info_xyp_cpl0043 === '2'
              ? '2000-58000'
              : '58000+',
        data?.loan_info_xyp_cpl0026 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0026 === '1'
            ? '0-3'
            : data?.loan_info_xyp_cpl0026 === '2'
              ? '3-25'
              : data?.loan_info_xyp_cpl0026 === '3'
                ? '25-30'
                : data?.loan_info_xyp_cpl0026 === '4'
                  ? '30-70'
                  : '70+',
        data?.loan_info_xyp_cpl0042 === '0'
          ? '0'
          : data?.loan_info_xyp_cpl0042 === '1'
            ? '0-2000'
            : data?.loan_info_xyp_cpl0042 === '2'
              ? '2000-30000'
              : data?.loan_info_xyp_cpl0042 === '3'
                ? '30000-60000'
                : data?.loan_info_xyp_cpl0042 === '4'
                  ? '60000-90000'
                  : '90000+',
      ],
    ],
  }

  return (
    <Stack>
      <LabeledTitle title={'贷款分周期汇总'} />
      <Table.ScrollContainer minWidth={800} type="native">
        <Table data={tableData} />
      </Table.ScrollContainer>
    </Stack>
  )
}
