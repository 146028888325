import { Stack, Table, TableData, Tabs } from '@mantine/core'
import LabeledTitle from '../../../components/LabeledTitle'

interface SpecialPeriodApplicationRecordsProps {
  data: any
}

export default function SpecialPeriodApplicationRecords({ data }: SpecialPeriodApplicationRecordsProps) {
  const lastSevenTableData: TableData = {
    head: ['时段', '申请次数', '申请机构数'],
    body: [
      ['周末', data?.nb_d7_week_apply_count ?? 0, data?.nb_d7_week_apply_org_count ?? 0],
      ['夜间', data?.nb_d7_night_apply_count ?? 0, data?.nb_d7_night_apply_org_count ?? 0],
    ],
  }

  const lastFifteenTableData: TableData = {
    head: ['时段', '申请次数', '申请机构数'],
    body: [
      ['周末', data?.nb_d15_week_apply_count ?? 0, data?.nb_d15_week_apply_org_count ?? 0],
      ['夜间', data?.nb_d15_night_apply_count ?? 0, data?.nb_d15_night_apply_org_count ?? 0],
    ],
  }

  const lastMonthTableData: TableData = {
    head: ['时段', '申请次数', '申请机构数'],
    body: [
      ['周末', data?.nb_m1_week_apply_count ?? 0, data?.nb_m1_week_apply_org_count ?? 0],
      ['夜间', data?.nb_m1_night_apply_count ?? 0, data?.nb_m1_night_apply_org_count ?? 0],
    ],
  }

  const lastThreeMonthsTableData: TableData = {
    head: ['时段', '申请次数', '申请机构数'],
    body: [
      ['周末', data?.nb_m3_week_apply_count ?? 0, data?.nb_m3_week_apply_org_count ?? 0],
      ['夜间', data?.nb_m3_night_apply_count ?? 0, data?.nb_m3_night_apply_org_count ?? 0],
    ],
  }

  const lastSixMonthsTableData: TableData = {
    head: ['时段', '申请次数', '申请机构数'],
    body: [
      ['周末', data?.nb_m6_week_apply_count ?? 0, data?.nb_m6_week_apply_org_count ?? 0],
      ['夜间', data?.nb_m6_night_apply_count ?? 0, data?.nb_m6_night_apply_org_count ?? 0],
    ],
  }

  const lastYearTableData: TableData = {
    head: ['时段', '申请次数', '申请机构数'],
    body: [
      ['周末', data?.nb_m12_week_apply_count ?? 0, data?.nb_m12_week_apply_org_count ?? 0],
      ['夜间', data?.nb_m12_night_apply_count ?? 0, data?.nb_m12_night_apply_org_count ?? 0],
    ],
  }

  return (
    <Stack>
      <LabeledTitle title={'特殊时段申请记录'} />
      <Tabs
        defaultValue="lastSeven"
        styles={{
          tab: {
            paddingRight: 8,
            paddingLeft: 8,
          },
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="lastSeven">近7天</Tabs.Tab>
          <Tabs.Tab value="lasetFifteen">近15天</Tabs.Tab>
          <Tabs.Tab value="lastMonth">近1月</Tabs.Tab>
          <Tabs.Tab value="lastThreeMonths">近3月</Tabs.Tab>
          <Tabs.Tab value="lastSixMonths">近6月</Tabs.Tab>
          <Tabs.Tab value="lastYear">近1年</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="lastSeven">
          <Table data={lastSevenTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lasetFifteen">
          <Table data={lastFifteenTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastMonth">
          <Table data={lastMonthTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastThreeMonths">
          <Table data={lastThreeMonthsTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastSixMonths">
          <Table data={lastSixMonthsTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastYear">
          <Table data={lastYearTableData} />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  )
}
