import { Title } from '@mantine/core'

interface LabeledTitleProps {
  title: string
  color?: string
}

export default function LabeledTitle({ title, color = 'blue' }: LabeledTitleProps) {
  return (
    <div className="flex items-center">
      <div className="w-2 h-4 bg-blue-500 mr-1"></div>
      <Title order={5}>{title}</Title>
    </div>
  )
}
