import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import NonBankingApplicationRecordsOverview from './NonBankingApplicationRecordsOverview'
import InstitutionalApplicationRecords from './InstitutionalApplicationRecords'
import SpecialPeriodApplicationRecords from './SpecialPeriodApplicationRecords'

interface NonBankingApplicationRecordsProps {
  data: any
  title: string
}

export default function NonBankingApplicationRecords({ data, title }: NonBankingApplicationRecordsProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <NonBankingApplicationRecordsOverview data={data} />
      <InstitutionalApplicationRecords data={data} />
      <SpecialPeriodApplicationRecords data={data} />
    </ReportWrapper>
  )
}
