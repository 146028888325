import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from '@mantine/form'
import { Box, Button, Center, Checkbox, Group, TextInput } from '@mantine/core'
import { IconPhone, IconShieldCheck, IconUser } from '@tabler/icons-react'
import useVerificationCodeTimer from '../../hooks/useVerificationCodeTimer'
import { signupMerchant } from '../../api/merchantService'
import { Dialog } from 'antd-mobile'

export default function InviteForm() {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { label, startTimer, isActive } = useVerificationCodeTimer()
  const [searchParams] = useSearchParams()
  const inviteMaskPhone = searchParams.get('inviteMaskPhone') || ''
  const inviteId = searchParams.get('inviteId') || ''
  const managerId = searchParams.get('managerId') || ''

  const form = useForm({
    initialValues: {
      name: '',
      phone: '',
      smsCode: '',
      inviteId: inviteId,
      managerId: managerId,
      termsOfService: false,
    },
    validate: {
      name: (value) => (value === '' ? '请输入姓名' : null),
      phone: (value) => (value === '' ? '请输入手机号' : !/^1[3-9]\d{9}$/.test(value) ? '请输入正确的手机号' : null),
      smsCode: (value) => (value === '' ? '请输入验证码' : value.length !== 6 ? '请输入6位的验证码' : null),
    },
  })

  const agreeTermsOfService = () => {
    return (
      <>
        我已阅读并同意
        <span
          className="text-orange-500"
          onClick={(e) => {
            e.preventDefault()
            // eslint-disable-next-line no-restricted-globals
            open('https://ucc.sjcyx.com/userAgreement.html', '_blank')
          }}
        >
          《用户协议》
        </span>
        &nbsp;
        <span
          className="text-orange-500"
          onClick={(e) => {
            e.preventDefault()
            // eslint-disable-next-line no-restricted-globals
            open('https://ucc.sjcyx.com/informationTechnologyService.html', '_blank')
          }}
        >
          《信息技术服务合同》
        </span>
        &nbsp;
        <span
          className="text-orange-500"
          onClick={(e) => {
            e.preventDefault()
            // eslint-disable-next-line no-restricted-globals
            open('https://ucc.sjcyx.com/promoterManagementService.html', '_blank')
          }}
        >
          《推广方管理制度》
        </span>
      </>
    )
  }

  const register = (values: any) => {
    setLoading(true)
    signupMerchant(values)
      .then((res) => {
        navigate('/success')
      })
      .catch((error) => {})
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Box>
      {inviteMaskPhone && <Center className={'text-gray-500 text-sm mb-4'}>{inviteMaskPhone} 邀请你使用优查查</Center>}
      <form
        onSubmit={form.onSubmit((values) => {
          if (!values.termsOfService) {
            Dialog.confirm({
              content: agreeTermsOfService(),
              confirmText: '同意并注册',
              cancelText: '不同意',
              onConfirm: () => {
                form.setFieldValue('termsOfService', true)
                register(values)
              },
            }).then()
          } else {
            register(values)
          }
        })}
      >
        <TextInput
          withAsterisk
          placeholder="请输入姓名"
          leftSection={<IconUser size={16} />}
          {...form.getInputProps('name')}
        />
        <TextInput
          mt="md"
          placeholder="请输入手机号"
          leftSection={<IconPhone size={16} />}
          {...form.getInputProps('phone')}
        />
        <TextInput
          mt="md"
          placeholder="请输入验证码"
          leftSection={<IconShieldCheck size={16} />}
          rightSectionWidth={100}
          maxLength={6}
          rightSection={
            <Button
              size="xs"
              variant="subtle"
              onClick={() => {
                const phone = form.values.phone
                const result = form.validateField('phone')
                if (result.hasError) {
                  return
                }
                startTimer({ phone: phone, type: 3 })
              }}
              disabled={isActive}
            >
              {label}
            </Button>
          }
          {...form.getInputProps('smsCode')}
        />
        <Checkbox
          my="md"
          color={'#6F84FC'}
          label={agreeTermsOfService()}
          {...form.getInputProps('termsOfService', { type: 'checkbox' })}
        />
        <Group justify="space-between" mt="md">
          <Button
            loading={loading}
            type="submit"
            fullWidth
            radius="md"
            size={'md'}
            styles={{
              root: {
                backgroundColor: '#6F84FC',
              },
            }}
          >
            立即注册
          </Button>
        </Group>
      </form>
    </Box>
  )
}
