import { Container, Title } from '@mantine/core'
import React from 'react'

interface LabeledContainerProps {
  title: string
}

export default function LabeledContainer({ title }: LabeledContainerProps) {
  return (
    <Container className={'bg-blue-500 text-white'}>
      <Title order={4}>{title}</Title>
    </Container>
  )
}
