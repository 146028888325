import ReportOverview from './ReportOverview'
import React, { ReactNode } from 'react'
import BasicDetail from './BasicDetail'
import NonBankingApplicationRecords from './NonBankingApplicationRecords'
import BankApplicationRecords from './BankApplicationRecords'
import ExceptionList from './ExceptionList'
import CourtCases from './CourtCases'
import FailureComplyCases from './FailureComplyCases'
import EnforcementCases from './EnforcementCases'
import LimitHighConsumptionCases from './LimitHighConsumptionCases'
import RelatedPartyAnomalies from './RelatedPartyAnomalies'
import SocialTag from './SocialTag'
import LoanRecords from './LoanRecords'
import BadInformationDetection from './BadInformationDetection'
import MarriageInformation from './MarriageInformation'
import BadInformationDetailDetection from './BadInformationDetailDetection'

export const getModuleName = (data: any, key: string) => {
  for (const item of data?.meta_data_module_list ?? []) {
    if (item.hasOwnProperty(key)) {
      return item[key]
    }
  }
}

export const getModuleNodes = (data: any): ReactNode[] => {
  return (data?.meta_data_module_list ?? []).map((item: any) => {
    const key = Object.keys(item)[0]
    switch (key) {
      case '001':
        return <ReportOverview data={data} title={getModuleName(data, '001')} />
      case '002':
        return <BasicDetail data={data} title={getModuleName(data, '002')} />
      case '003':
        return <BankApplicationRecords data={data} title={getModuleName(data, '003')} />
      case '004':
        return <NonBankingApplicationRecords data={data} title={getModuleName(data, '004')} />
      case '010':
        return <ExceptionList data={data} title={getModuleName(data, '010')} />
      case '018':
        return <CourtCases data={data} title={getModuleName(data, '018')} />
      case '017':
        return <FailureComplyCases data={data} title={getModuleName(data, '017')} />
      case '016':
        return <EnforcementCases data={data} title={getModuleName(data, '016')} />
      case '015':
        return <LimitHighConsumptionCases data={data} title={getModuleName(data, '015')} />
      case '011':
        return <RelatedPartyAnomalies data={data} title={getModuleName(data, '011')} />
      case '008':
        return <SocialTag data={data} title={getModuleName(data, '008')} />
      case '014':
        return <LoanRecords data={data} title={getModuleName(data, '014')} />
      case '009':
        return <BadInformationDetection data={data} title={getModuleName(data, '009')} />
      case '012':
        return <MarriageInformation data={data} title={getModuleName(data, '012')} />
      case '013':
        return <BadInformationDetailDetection data={data} title={getModuleName(data, '013')} />
      case '019':
        return <BankApplicationRecords data={data} title={getModuleName(data, '019')} />
      case '020':
        return <NonBankingApplicationRecords data={data} title={getModuleName(data, '020')} />
      default:
        return null
    }
  })
}
