import { SegmentedControl, Stack } from '@mantine/core'
import LabeledTitle from '../../../components/LabeledTitle'
import ReportItem from '../ReportItem'
import { useState } from 'react'

interface RelatedPartyUnusualBehaviorProps {
  data: any
}

export default function RelatedPartyUnusualBehavior({ data }: RelatedPartyUnusualBehaviorProps) {
  const [value, setValue] = useState('normal')
  return (
    <Stack>
      <LabeledTitle title={'关联人异常行为检测'} />
      <SegmentedControl
        value={value}
        onChange={setValue}
        data={[
          { label: '普通异常', value: 'normal' },
          { label: '严重异常', value: 'serious' },
        ]}
      />
      <ReportItem
        name={'是否有银行失联欺诈行为'}
        value={value === 'normal' ? (data?.aals_00021 === 1 ? '是' : '否') : data?.aals_00031 === 1 ? '是' : '否'}
        isNormal={value === 'normal' ? data?.aals_00021 !== 1 : data?.aals_00031 !== 1}
      />
      <ReportItem
        name={'是否有网络小贷欺诈关联'}
        value={value === 'normal' ? (data?.aals_00022 === 1 ? '是' : '否') : data?.aals_00032 === 1 ? '是' : '否'}
        isNormal={value === 'normal' ? data?.aals_00022 !== 1 : data?.aals_00032 !== 1}
      />
      <ReportItem
        name={'是否有地方小贷欺诈关联'}
        value={value === 'normal' ? (data?.aals_00023 === 1 ? '是' : '否') : data?.aals_00033 === 1 ? '是' : '否'}
        isNormal={value === 'normal' ? data?.aals_00023 !== 1 : data?.aals_00033 !== 1}
      />
      <ReportItem
        name={'是否有消费金融公司欺诈关联'}
        value={value === 'normal' ? (data?.aals_00024 === 1 ? '是' : '否') : data?.aals_00034 === 1 ? '是' : '否'}
        isNormal={value === 'normal' ? data?.aals_00024 !== 1 : data?.aals_00034 !== 1}
      />
      <ReportItem
        name={'是否有融资租赁公司欺诈关联'}
        value={value === 'normal' ? (data?.aals_00025 === 1 ? '是' : '否') : data?.aals_00035 === 1 ? '是' : '否'}
        isNormal={value === 'normal' ? data?.aals_00025 !== 1 : data?.aals_00035 !== 1}
      />
      <ReportItem
        name={'是否有汽车金融公司欺诈关联'}
        value={value === 'normal' ? (data?.aals_00026 === 1 ? '是' : '否') : data?.aals_00036 === 1 ? '是' : '否'}
        isNormal={value === 'normal' ? data?.aals_00026 !== 1 : data?.aals_00036 !== 1}
      />
      <ReportItem
        name={'是否有其他金融公司欺诈关联'}
        value={value === 'normal' ? (data?.aals_00027 === 1 ? '是' : '否') : data?.aals_00037 === 1 ? '是' : '否'}
        isNormal={value === 'normal' ? data?.aals_00027 !== 1 : data?.aals_00037 !== 1}
      />
    </Stack>
  )
}
