import { Stack, Table, TableData, Tabs } from '@mantine/core'
import LabeledTitle from '../../../components/LabeledTitle'

interface InstitutionalApplicationRecordsProps {
  data: any
}

export default function InstitutionalApplicationRecords({ data }: InstitutionalApplicationRecordsProps) {
  const lastSevenTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['传统银行', data?.b_d7_apply_ctyh_count ?? 0, data?.b_d7_apply_ctyh_org_count ?? 0],
      ['网络零售银行', data?.b_d7_apply_wllsyh_count ?? 0, data?.b_d7_apply_wllsyh_org_count ?? 0],
    ],
  }

  const lastFifteenTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['传统银行', data?.b_d15_apply_ctyh_count ?? 0, data?.b_d15_apply_ctyh_org_count ?? 0],
      ['网络零售银行', data?.b_d15_apply_wllsyh_count ?? 0, data?.b_d15_apply_wllsyh_org_count ?? 0],
    ],
  }

  const lastMonthTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['传统银行', data?.b_m1_apply_ctyh_count ?? 0, data?.b_m1_apply_ctyh_org_count ?? 0],
      ['网络零售银行', data?.b_m1_apply_wllsyh_count ?? 0, data?.b_m1_apply_wllsyh_org_count ?? 0],
    ],
  }

  const lastThreeMonthsTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['传统银行', data?.b_m3_apply_ctyh_count ?? 0, data?.b_m3_apply_ctyh_org_count ?? 0],
      ['网络零售银行', data?.b_m3_apply_wllsyh_count ?? 0, data?.b_m3_apply_wllsyh_org_count ?? 0],
    ],
  }

  const lastSixMonthsTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['传统银行', data?.b_m6_apply_ctyh_count ?? 0, data?.b_m6_apply_ctyh_org_count ?? 0],
      ['网络零售银行', data?.b_m6_apply_wllsyh_count ?? 0, data?.b_m6_apply_wllsyh_org_count ?? 0],
    ],
  }

  const lastYearTableData: TableData = {
    head: ['机构类别', '申请次数', '申请机构数'],
    body: [
      ['传统银行', data?.b_m12_apply_ctyh_count ?? 0, data?.b_m12_apply_ctyh_org_count ?? 0],
      ['网络零售银行', data?.b_m12_apply_wllsyh_count ?? 0, data?.b_m12_apply_wllsyh_org_count ?? 0],
    ],
  }
  return (
    <Stack>
      <LabeledTitle title={'机构类别申请记录'} />
      <Tabs
        defaultValue="lastSeven"
        styles={{
          tab: {
            paddingRight: 8,
            paddingLeft: 8,
          },
        }}
      >
        <Tabs.List>
          <Tabs.Tab value="lastSeven">近7天</Tabs.Tab>
          <Tabs.Tab value="lasetFifteen">近15天</Tabs.Tab>
          <Tabs.Tab value="lastMonth">近1月</Tabs.Tab>
          <Tabs.Tab value="lastThreeMonths">近3月</Tabs.Tab>
          <Tabs.Tab value="lastSixMonths">近6月</Tabs.Tab>
          <Tabs.Tab value="lastYear">近1年</Tabs.Tab>
        </Tabs.List>
        <Tabs.Panel value="lastSeven">
          <Table data={lastSevenTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lasetFifteen">
          <Table data={lastFifteenTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastMonth">
          <Table data={lastMonthTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastThreeMonths">
          <Table data={lastThreeMonthsTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastSixMonths">
          <Table data={lastSixMonthsTableData} />
        </Tabs.Panel>
        <Tabs.Panel value="lastYear">
          <Table data={lastYearTableData} />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  )
}
