import { Anchor } from '@mantine/core'

export default function Footer() {
  return (
    <footer className="bg-black bg-cover bg-center text-white">
      <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-24 lg:py-24">
        <div className="flex flex-col items-center">
          <h1 className="bg-[url('https://assets.website-files.com/63904f663019b0d8edf8d57c/6390526ac2a607693620c97b_Rectangle%2010.svg')] bg-cover bg-center px-4 text-white font-semibold text-4xl md:text-6xl mb-8">
            优查查
          </h1>
          <div className="text-center font-semibold max-[991px]:ml-0 max-[991px]:mr-0 max-[991px]:py-1">
            <span className="inline-block px-6 py-2 font-normal transition hover:text-[#d6a701]">关于我们</span>
            <span className="inline-block px-6 py-2 font-normal transition hover:text-[#d6a701]">联系我们</span>
          </div>
          <div className="mb-8 mt-8 w-48 border-b border-solid border-b-white"></div>
          <p className="max-[479px]:text-sm">Copyright © 2023-2024 四川捷创优信科技有限公司版权所有</p>
          <Anchor size={'sm'} href="https://beian.miit.gov.cn/" target="_blank">
            蜀ICP备2024054454号
          </Anchor>
        </div>
      </div>
    </footer>
  )
}
