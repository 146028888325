import http, { ResponseData } from './httpService'
import { SendSmsCodeInterface } from '../typings/types'

export const sendSmsCode = async (param: SendSmsCodeInterface): Promise<ResponseData<String>> => {
  const { data } = await http.post<ResponseData<String>>(
    '/fe/user/center/verification/sendSmsCode',
    { ...param },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  )
  return data
}
