import LabeledContainer from '../../../components/LabeledContainer'
import ReportWrapper from '../ReportWrapper'
import React from 'react'
import BankOutOfConnectionFraud from './BankOutOfConnectionFraud'
import OnlineLendingOutOfConnectionFraud from './OnlineLendingOutOfConnectionFraud'

interface ExceptionListProps {
  data: any
  title: string
}

export default function ExceptionList({ data, title }: ExceptionListProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <BankOutOfConnectionFraud data={data} />
      <OnlineLendingOutOfConnectionFraud data={data} />
    </ReportWrapper>
  )
}
