import React, { useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from '@mantine/form'
import { Box, Button, Checkbox, Group, Stack, Text, TextInput } from '@mantine/core'
import { IconAlertCircle, IconId, IconPhone, IconShieldCheck, IconUser } from '@tabler/icons-react'
import useVerificationCodeTimer from '../../hooks/useVerificationCodeTimer'
import { create } from '../../api/orderService'
import { TemplateMerchantInterface } from '../../typings/orderTypes'
import { Dialog, Popup } from 'antd-mobile'
import PowerOfAttorney from '../../Service/PowerOfAttorney'
import { USER_TOKEN_KEY } from '../../constants/localStorageConstants'

export default function QueryReportForm({ template }: { template: TemplateMerchantInterface | undefined }) {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const { label, startTimer, isActive } = useVerificationCodeTimer()
  const [searchParams] = useSearchParams()
  const linkId = searchParams.get('linkId') || ''
  const [currentPop, setCurrentPop] = useState<React.ReactNode>()
  const [popVisible, setPopVisible] = useState(false)

  const form = useForm({
    initialValues: {
      name: '',
      phone: '',
      idCard: '',
      smsCode: '',
      termsOfService: false,
    },
    validate: {
      phone: (value) => (value === '' ? '请输入手机号' : !/^1[3-9]\d{9}$/.test(value) ? '请输入正确的手机号' : null),
      smsCode: (value) => (value === '' ? '请输入验证码' : value.length !== 6 ? '请输入6位的验证码' : null),
      idCard: (value) =>
        value === ''
          ? '请输入身份证号'
          : !/(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/.test(value)
            ? '请输入正确的身份证号'
            : null,
      name: (value) => (value === '' ? '请输入姓名' : null),
    },
  })

  const agreeTermsOfService = () => {
    return (
      <>
        我已阅读并同意
        <span
          className="text-orange-500"
          onClick={(e) => {
            e.preventDefault()
            // eslint-disable-next-line no-restricted-globals
            open('https://ucc.sjcyx.com/userAgreement.html', '_blank')
          }}
        >
          《用户协议》
        </span>
        &nbsp;
        <span
          className="text-orange-500"
          onClick={(e) => {
            e.preventDefault()
            // eslint-disable-next-line no-restricted-globals
            open('https://ucc.sjcyx.com/privacyPolicy.html', '_blank')
          }}
        >
          《隐私政策》
        </span>
        &nbsp;
        <span
          className="text-orange-500"
          onClick={(e) => {
            setPopVisible(true)
            setCurrentPop(
              <PowerOfAttorney
                merchantPhone={template?.merchantPhoneMask}
                name={form.values.name}
                idCard={form.values.idCard}
              />
            )
            e.preventDefault()
          }}
        >
          《授权书》
        </span>
      </>
    )
  }

  const createOrder = (values: any) => {
    Dialog.confirm({
      content: (
        <>
          <span className="text-gray-700">
            您即将进行<span className="text-red-500 font-bold">自愿查询</span>大数据报告，请确认：
          </span>
          <span className="leading-7 text-gray-700">
            1. 本次查询为<span className="text-red-500 font-bold">完全自愿</span>，由本人发起并确认；
          </span>
          <span className="leading-7 text-gray-700">
            2. 报告信息来源于<span className="text-red-500 font-bold">第三方数据服务提供商</span>，仅供参考；
          </span>
          <span className="leading-7 text-gray-700">
            3. 报告内容<span className="text-red-500 font-bold">不作为任何正式用途或决策的依据</span>。
          </span>
        </>
      ),
      confirmText: '确认并查询',
      cancelText: '取消',
      onConfirm: () => {
        setLoading(true)
        create({
          ...values,
          linkId: linkId,
        })
          .then((data) => {
            localStorage.setItem(USER_TOKEN_KEY, data.token?.tokenValue)
            if (data.status === 35) {
              Dialog.alert({
                content: '代理商已开启自助收银模式，请联系代理商查询报告或点击右下角按钮联系客服处理。',
                onConfirm: () => {},
              }).then()
              return
            }
            navigate(
              '/payment?orderId=' +
                data.orderId +
                '&templateName=' +
                template?.name +
                '&templatePrice=' +
                template?.price
            )
          })
          .catch(() => {})
          .finally(() => {
            setLoading(false)
          })
      },
    }).then()
  }

  const getButtonText = () => {
    if (template?.merchantId === '00178878219265302528') {
      return '立即查询'
    }
    return `¥${(template?.price ?? 0).toFixed(2)} 立即查询`
  }

  return (
    <Box>
      <div className={'flex justify-between'}>
        <div className={'text-left text-lg font-bold'}>{template?.name}</div>
        <div
          className={'text-orange-500 text-sm'}
          onClick={() => {
            navigate('/reportSamples/' + template?.templateId)
          }}
        >
          示例报告
        </div>
      </div>
      <div className={'text-gray-500 text-sm mb-4'}>为保证数据准确，请输入真实信息</div>
      <form
        onSubmit={form.onSubmit((values) => {
          if (!values.termsOfService) {
            Dialog.confirm({
              content: agreeTermsOfService(),
              confirmText: '同意并查询',
              cancelText: '不同意',
              onConfirm: () => {
                form.setFieldValue('termsOfService', true)
                createOrder(values)
              },
            }).then()
          } else {
            createOrder(values)
          }
        })}
      >
        <TextInput placeholder="请输入本人姓名" leftSection={<IconUser size={16} />} {...form.getInputProps('name')} />
        <TextInput
          mt="md"
          placeholder="请输入本人身份证号"
          leftSection={<IconId size={16} />}
          {...form.getInputProps('idCard')}
        />
        <TextInput
          mt="md"
          placeholder="请输入本人手机号"
          leftSection={<IconPhone size={16} />}
          {...form.getInputProps('phone')}
        />
        <TextInput
          mt="md"
          placeholder="请输入验证码"
          maxLength={6}
          leftSection={<IconShieldCheck size={16} />}
          rightSectionWidth={94}
          rightSection={
            <Button
              size="xs"
              variant="subtle"
              onClick={() => {
                const phone = form.values.phone
                const result = form.validateField('phone')
                if (result.hasError) {
                  return
                }
                startTimer({ phone: phone, type: 6 })
              }}
              disabled={isActive}
            >
              {label}
            </Button>
          }
          {...form.getInputProps('smsCode')}
        />
        <Checkbox
          mt="md"
          color={'#3A55DD'}
          label={agreeTermsOfService()}
          {...form.getInputProps('termsOfService', { type: 'checkbox' })}
        />

        <Group justify="space-between" mt="md">
          <Button
            loading={loading}
            type="submit"
            fullWidth
            radius="md"
            size={'md'}
            styles={{
              root: {
                backgroundColor: '#3A55DD',
              },
            }}
          >
            <Text>{getButtonText()}</Text>
          </Button>
        </Group>
        <Stack className={'mt-4 text-sm text-gray-500'}>
          <Group>
            <IconAlertCircle size={16} color={'#3A55DD'} />
            <div style={{ flex: 1, minWidth: 0 }}>
              <span className="text-sm text-gray-500">
                本人对查询输入的三要素的真实性负责，如有虚假， 愿承担由此产生的一切后果！
              </span>
            </div>
          </Group>
          <Group>
            <IconAlertCircle size={16} color={'#3A55DD'} />
            <div style={{ flex: 1, minWidth: 0 }}>
              <span className="text-sm text-gray-500">请务必输入查询人实名手机号，查询的非公开结果以手机号为准。</span>
            </div>
          </Group>
          <Group>
            <IconAlertCircle size={16} color={'#3A55DD'} />
            <div style={{ flex: 1, minWidth: 0 }}>
              <span className="text-sm text-gray-500">不得涉及金融场景的应用。</span>
            </div>
          </Group>
          <Stack gap={4}>
            <span>1. 已付费查询用户可点击右侧历史报告查看报告。 </span>
            <span>2. 付费之后报告在十分钟内未生成或者对报告数据存在任何疑问，可点击右下角按钮联系客服咨询。 </span>
          </Stack>
        </Stack>
      </form>
      <Popup
        visible={popVisible}
        showCloseButton={true}
        onMaskClick={() => {
          setPopVisible(false)
        }}
        onClose={() => {
          setPopVisible(false)
        }}
        bodyStyle={{
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
        }}
      >
        <Box className="p-4">
          <Box className={'h-[40vh] my-6 overflow-y-scroll'}>{currentPop}</Box>
          <Button
            fullWidth
            onClick={() => {
              setPopVisible(false)
              form.setFieldValue('termsOfService', true)
            }}
            styles={{
              root: {
                backgroundColor: '#3A55DD',
              },
            }}
          >
            我已阅读并同意此协议
          </Button>
        </Box>
      </Popup>
    </Box>
  )
}
