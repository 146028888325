import { Badge } from '@mantine/core'
import React from 'react'

interface ReportElementProps {
  name: string
}

export default function ReportElement({ name }: ReportElementProps) {
  return (
    <Badge size="lg" variant="gradient" gradient={{ from: 'blue', to: 'cyan', deg: 90 }}>
      {name}
    </Badge>
  )
}
