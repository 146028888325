import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import ReportWrapper from '../ReportWrapper'
import ReportItem from '../ReportItem'

export default function MarriageInformation({ data, title }: { data: any; title: string }) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ReportItem name={'婚姻情况'} value={data?.marriage_info} />
    </ReportWrapper>
  )
}
