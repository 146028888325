import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import LabeledTitle from '../../../components/LabeledTitle'
import LimitHighConsumptionCase from './LimitHighConsumptionCase'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

interface LimitHighConsumptionCasesProps {
  data: any
  title: string
}

export default function LimitHighConsumptionCases({ data, title }: LimitHighConsumptionCasesProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <LabeledTitle title={'限高执行'} />
      <ExpandableListWrapper
        isEmpty={(data?.limited_case_info ?? []).length === 0 || data?.limited_case_info === undefined}
      >
        {(data?.limited_case_info ?? []).map((item: any, index: number) => {
          return <LimitHighConsumptionCase key={index} number={index + 1} item={item} />
        })}
      </ExpandableListWrapper>
    </ReportWrapper>
  )
}
