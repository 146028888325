import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import LabeledTitle from '../../../components/LabeledTitle'
import FailureComplyCase from './FailureComplyCase'
import ExpandableListWrapper from '../../../components/ExpandableListWrapper'

interface FailureComplyCasesProps {
  data: any
  title: string
}

export default function FailureComplyCases({ data, title }: FailureComplyCasesProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <LabeledTitle title={'失信案件'} />
      <ExpandableListWrapper
        isEmpty={(data?.dishonest_case_info ?? []).length === 0 || data?.dishonest_case_info === undefined}
      >
        {(data?.dishonest_case_info ?? []).map((item: any, index: number) => {
          return <FailureComplyCase number={index + 1} key={index} item={item} />
        })}
      </ExpandableListWrapper>
    </ReportWrapper>
  )
}
