import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios'
import { USER_TOKEN_KEY } from '../constants/localStorageConstants'
import { Toast } from 'antd-mobile'

export interface ResponseData<T = any> {
  errorCode: number
  data: T
  errorMessage: string
  success: boolean
}

const http: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 10000,
})

http.interceptors.request.use(
  (
    config: InternalAxiosRequestConfig<any>
  ): InternalAxiosRequestConfig<any> | Promise<InternalAxiosRequestConfig<any>> => {
    const token = localStorage.getItem(USER_TOKEN_KEY)
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`
    }
    return config
  },
  (error: AxiosError): Promise<AxiosError> => {
    return Promise.reject(error)
  }
)

http.interceptors.response.use(
  (response: AxiosResponse<ResponseData>) => {
    if (response.data.errorCode === 401) {
      localStorage.removeItem(USER_TOKEN_KEY)
      window.location.href = '/login'
    }
    if (!response.data.success) {
      Toast.show({
        content: response.data.errorMessage,
      })
      return Promise.reject(response.data)
    }
    return response
  },
  (error: AxiosError): Promise<AxiosError> => {
    if (error.response?.status !== 200) {
      Toast.show({
        content: '网络异常，请稍后重试',
      })
    }
    return Promise.reject(error)
  }
)

export default http
