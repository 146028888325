import { Drawer, Image, SimpleGrid, Stack, Text } from '@mantine/core'
import React from 'react'
import { Toast } from 'antd-mobile'

export default function Share({
  shareVisible,
  setShareVisible,
}: {
  shareVisible: boolean
  setShareVisible: (visible: boolean) => void
}) {
  const url = window.location.protocol + '//' + window.location.host + window.location.pathname
  const Item = (image: string, text: string, onClick: () => void) => {
    return (
      <Stack align="center" justify="center" onClick={onClick}>
        <Image src={image} h={40} w={40} />
        <Text>{text}</Text>
      </Stack>
    )
  }
  return (
    <Drawer
      withCloseButton={false}
      size={120}
      opened={shareVisible}
      position={'bottom'}
      onClose={() => {
        setShareVisible(false)
      }}
    >
      <SimpleGrid cols={1}>
        {Item('https://file.sjcyx.com/save_by_url.png', '复制链接', () => {
          navigator.clipboard
            .writeText(url)
            .then(() => {
              Toast.show({
                content: '复制成功',
              })
            })
            .catch(() => {
              Toast.show({
                content: '复制失败, 请手动复制链接或者浏览器分享',
              })
            })
        })}
      </SimpleGrid>
    </Drawer>
  )
}
