import React from 'react'
import { Button } from '@mantine/core'

interface FloatingButtonProps {
  color: 'yellow' | 'green' | 'red' | 'blue' | 'gray' | 'white' | 'black'
  icon?: React.ReactNode
  label: string
  onClick?: () => void
}

const FloatingButton: React.FC<FloatingButtonProps> = ({ color, icon, label, onClick }) => {
  return (
    <Button
      onClick={onClick}
      color={color}
      leftSection={icon}
      styles={{
        root: {
          borderRadius: '12px',
          marginRight: '-12px',
        },
      }}
    >
      {label}
    </Button>
  )
}

export default FloatingButton
