import React from 'react'
import { ResultPage } from 'antd-mobile'
import { Button, Center } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'

export default function Success() {
  const isAndroid = () => {
    const u = navigator.userAgent
    if (u.indexOf('Android') > -1 || u.indexOf('Linux') > -1) {
      return true
    }
    return false
  }
  return (
    <ResultPage
      status="success"
      title="注册成功"
      description="恭喜您注册成功，现在可以通过【苹果应用市场、小米应用商店、应用宝 App】下载优查查APP进行使用了！"
      style={{
        '--background-color': '#6F84FD',
      }}
    >
      <Center className="mt-[-12px]">
        <Button
          variant="filled"
          color="#000"
          radius="lg"
          leftSection={<IconDownload />}
          onClick={() => {
            window.location.href = isAndroid()
              ? 'https://www.pgyer.com/isaac'
              : 'https://apps.apple.com/cn/app/%E4%BC%98%E6%9F%A5%E6%9F%A5/id6478474182'
          }}
        >
          下载APP
        </Button>
      </Center>
    </ResultPage>
  )
}
