import React from 'react'
import ReportItem from '../ReportItem'
import LabeledContainer from '../../../components/LabeledContainer'
import ReportWrapper from '../ReportWrapper'
import { getModuleName } from '../ModuleUtil'

interface BasicDetailProps {
  data: any
  title: string
}

export default function BasicDetail({ data, title }: BasicDetailProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <ReportItem name={'姓名'} value={data?.basic_info_name} />
      <ReportItem name={'性别'} value={data?.basic_info_sex === 1 ? '男' : '女'} />
      <ReportItem name={'年龄'} value={data?.basic_info_age} />
      <ReportItem name={'星座'} value={data?.basic_info_constellation} />
      <ReportItem name={'身份证号'} value={data?.basic_info_id_card} />
      <ReportItem name={'身份证发放地'} value={data?.basic_info_id_card_zone} />
      <ReportItem name={'手机号'} value={data?.basic_info_phone} />
      <ReportItem name={'手机号归属地'} value={data?.basic_info_phone_zone} />
      <ReportItem
        name={'手机号检测结果'}
        isNormal={data?.basic_info_check === '1'}
        value={data?.basic_info_check === '1' ? '被查询人姓名与运营商提供的一致' : '被查询人姓名与运营商提供的不一致'}
      />
    </ReportWrapper>
  )
}
