import ReportWrapper from '../ReportWrapper'
import LabeledContainer from '../../../components/LabeledContainer'
import React from 'react'
import RelatedCasesStatistics from './RelatedCasesStatistics'
import CriminalCases from './CriminalCases'
import CivilCases from './CivilCases'
import OtherCases from './OtherCases'

interface CourtCasesProps {
  data: any
  title: string
}

export default function CourtCases({ data, title }: CourtCasesProps) {
  return (
    <ReportWrapper>
      <LabeledContainer title={title} />
      <RelatedCasesStatistics data={data} />
      <CriminalCases data={data} />
      <CivilCases data={data} />
      <OtherCases data={data} />
    </ReportWrapper>
  )
}
