import { Box, Stack, Text } from '@mantine/core'
import ReportItem from '../ReportItem'
import React from 'react'

interface LimitHighConsumptionCaseProps {
  number: number
  item: any
}

export default function LimitHighConsumptionCase({ number, item }: LimitHighConsumptionCaseProps) {
  return (
    <Box
      bg={'var(--mantine-color-blue-light)'}
      p={'xs'}
      style={{
        borderRadius: 'var(--mantine-radius-sm)',
      }}
    >
      <Stack gap={'xs'} className={'text-gray-500'}>
        <Text size={'sm'} c={'dark'} fw={500}>
          案件（{number}）
        </Text>
        <ReportItem key={'caseCode'} name={'案号'} value={item?.caseCode} />
        <ReportItem key={'courtName'} name={'法院名称'} value={item?.courtName} />
        <ReportItem key={'caseRegTime'} name={'立案时间'} value={item?.caseRegTime} />
        <ReportItem key={'casePublishTime'} name={'发布时间'} value={item?.casePublishTime} />
      </Stack>
    </Box>
  )
}
