import React from 'react'
import { Badge, Card, Group, Stack, Text } from '@mantine/core'
import { useNavigate } from 'react-router-dom'
import { OrderInterface } from '../../typings/orderTypes'
import { formatDateToString } from '../../utils/dateTimeUtil'

interface OrderProps {
  order: OrderInterface
  statusMap: Map<string, string>
}

export default function Order({ order, statusMap }: OrderProps) {
  const navigate = useNavigate()
  return (
    <Card
      shadow="sm"
      padding="md"
      radius="lg"
      onClick={() => {
        navigate('/reports/' + order.id)
      }}
    >
      <Stack gap={'xs'}>
        <Group justify="space-between">
          <Text fw={'500'}>{order.templateName}</Text>
          <Text c={'dimmed'}>
            {Object.entries(statusMap).find((entry) => entry[0] === order.status.toString())?.[1]}
          </Text>
        </Group>
        <Group gap={'xs'}>
          <Badge color="orange" size={'md'}>
            {order.nameMask}
          </Badge>
          <Badge color="blue" size={'md'}>
            {order.phoneMask}
          </Badge>
        </Group>
        <Group justify="space-between">
          <Text c={'dimmed'} size={'sm'}>
            {formatDateToString(order.createTime)}
          </Text>
          <Text c={'blue'} size={'sm'}>
            查看报告
          </Text>
        </Group>
      </Stack>
    </Card>
  )
}
